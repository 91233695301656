<template>
  <div>
    <NurseDetails
      showTreatmentHistory="false"
      adminApprovalVar="true"
      backRoute="/admin/app-user"
    ></NurseDetails>
    <vs-card>
      <div slot="header">
        <feather-icon
          icon="ChevronLeftIcon"
          svgClasses="h-4 w-4"
          class="mr-2 cursor-pointer"
          @click="$router.push({ name: 'app-user' })"
        ></feather-icon>
        <vs-label style="font-weight: bold">App User List</vs-label>
      </div>
      <h2 class="mb-5 ml-5">User Details</h2>
      <p class="ml-5">
        - {{ this.userData.firstName | capitalize }}
        {{ this.userData.lastName | capitalize }} -
        {{ this.userData.userType | capitalize }}
      </p>
      <vs-form>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
            class="vs-con-loading__container"
            id="profileImageUpload"
          >
            <div class="w-full m-5">
              <vs-col vs-align="left" vs-w="4">
                <label for="profileImage">Profile Image</label>
                <a
                  style="display: block"
                  id="profileImage"
                  v-if="userData.profileImage"
                  :href="userData.profileImage"
                  target="_blank"
                >
                  <vs-avatar size="100px" :src="userData.profileImage" />
                </a>
                <p v-else id="profileImage">NA</p>
              </vs-col>
              <vs-col vs-align="left" vs-w="4">
                <div
                  class="mt-3"
                  style="text-align: center; position: relative"
                >
                  <label
                    class="image-upload"
                    style="
                      border: 1px solid #3dc9b3;
                      display: inline-block;
                      width: 100%;
                      border-radius: 25px;
                      padding: 5px;
                    "
                  >
                    Change Photo
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg"
                      @change="uploadImage($event, 'profileImageUpload')"
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </vs-col>
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('firstName') && userData.firstName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('firstName') ? true : false"
                :danger-text="errors.first('firstName')"
                v-validate="'required|max:150'"
                name="firstName"
                data-vv-as="first name"
                label="First Name"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                class="w-full"
                v-model="userData.firstName"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="!errors.first('lastName') && userData.lastName != ''"
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('lastName') ? true : false"
                v-validate="'required|max:150'"
                name="lastName"
                data-vv-as="last name"
                label="Last Name"
                class="w-full"
                :danger-text="errors.first('lastName')"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                v-model="userData.lastName"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('preferredName') && userData.preferredName != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('preferredName') ? true : false"
                v-validate="'max:150'"
                name="preferredName"
                data-vv-as="preferred name"
                label="Preferred Name(Optional)"
                class="w-full"
                :danger-text="errors.first('preferredName')"
                icon-no-border
                icon="icon icon-user"
                icon-pack="feather"
                v-model="userData.preferredName"
              />
            </div>
          </vs-col>

          <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
             <div class="w-full m-5">
               <vs-input
                 :success="!errors.first('email') && userData.email!=''"
                 val-icon-success="done"
                 val-icon-danger="error"
                 :danger="(errors.first('email') ? true : false)"
                 v-validate="'required|email'"
                 name="email"
                 data-vv-as="email"
                 label="Email"
                 class="w-full"
                 :danger-text="errors.first('email')"
                 icon-no-border
                 icon="icon icon-mail"
                 icon-pack="feather"
                 v-model="userData.email"/>
             </div>
           </vs-col>
          -->
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('contactNumber') && userData.contactNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('contactNumber') ? true : false"
                v-validate="'required'"
                name="contactNumber"
                :danger-text="errors.first('contactNumber')"
                data-vv-as="contact number"
                label="Contact Number"
                class="w-full"
                icon-no-border
                icon="icon icon-phone"
                icon-pack="feather"
                v-model="userData.contactNumber"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('ahpraRegistrationNumber') &&
                  userData.ahpraRegistrationNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('ahpraRegistrationNumber') ? true : false"
                v-validate="'required'"
                name="ahpraRegistrationNumber"
                :danger-text="errors.first('ahpraRegistrationNumber')"
                data-vv-as="AHPRA Registration Number"
                label="AHPRA Registration Number"
                class="w-full"
                icon-no-border
                icon="icon icon-briefcase"
                icon-pack="feather"
                v-model="userData.ahpraRegistrationNumber"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label>AHPRA Expiry date</label>
              <datepicker
                format="dd/MM/yyyy"
                :disabled-dates="disabledDates"
                v-model="userData.ahpraExiryDate"
                :input-class="{
                  'is-true':
                    !errors.has('ahpraExiryDate') && userData.ahpraExiryDate,
                  'is-danger': errors.has('ahpraExiryDate'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="userData.ahpraExiryDate"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('ahpraExiryDate')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger text-xxsm">
                {{ errors.first("ahpraExiryDate") }}
              </span>
              <input
                type="hidden"
                data-vv-as="APHRA expiry date"
                data-vv-validate-on="change"
                name="ahpraExiryDate"
                v-validate="'required'"
                v-model="userData.ahpraExiryDate"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('indemnityInsuranceProvider') &&
                  userData.indemnityInsuranceProvider != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('indemnityInsuranceProvider') ? true : false
                "
                v-validate="'required'"
                :danger-text="errors.first('indemnityInsuranceProvider')"
                name="indemnityInsuranceProvider"
                data-vv-as="indemnity insurance provider"
                label="Indemnity Insurance Provider"
                class="w-full"
                icon-no-border
                icon="icon icon-file"
                icon-pack="feather"
                v-model="userData.indemnityInsuranceProvider"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('indemnityInsuranceNumber') &&
                  userData.indemnityInsuranceNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('indemnityInsuranceNumber') ? true : false
                "
                v-validate="'required'"
                name="indemnityInsuranceNumber"
                :danger-text="errors.first('indemnityInsuranceNumber')"
                data-vv-as="indemnity insurance number"
                label="Indemnity Insurance number"
                class="w-full"
                icon-no-border
                icon="icon icon-file"
                icon-pack="feather"
                v-model="userData.indemnityInsuranceNumber"
              />
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label>Indemnity Insurance Expiry</label>
              <datepicker
                format="dd/MM/yyyy"
                :disabled-dates="disabledDates"
                v-model="userData.indemnityInsuranceExpiry"
                :input-class="{
                  'is-true':
                    !errors.first('indemnityInsuranceExpiry') &&
                    userData.indemnityInsuranceExpiry,
                  'is-danger': errors.first('indemnityInsuranceExpiry'),
                }"
              >
                <template slot="afterDateInput">
                  <span
                    v-if="userData.indemnityInsuranceExpiry"
                    class="
                      bg-color-done
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >done</i
                    >
                  </span>
                  <span
                    v-if="errors.has('indemnityInsuranceExpiry')"
                    class="
                      bg-color-error
                      input-icon-validate
                      vs-input--icon-validate
                    "
                  >
                    <i
                      valiconpack="material-icons"
                      class="vs-icon notranslate icon-scale material-icons null"
                      >error</i
                    >
                  </span>
                </template>
              </datepicker>
              <span class="text-danger text-xxsm">
                {{ errors.first("indemnityInsuranceExpiry") }}
              </span>
              <input
                type="hidden"
                data-vv-as="Indemnity Insurance Expiry"
                data-vv-validate-on="change"
                name="indemnityInsuranceExpiry"
                v-validate="'required'"
                v-model="userData.indemnityInsuranceExpiry"
                class="w-full"
              />
            </div>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
            v-if="this.userData.userType === 'doctor'"
          >
            <div class="w-full m-5">
              <vs-input
                :success="
                  !errors.first('prescriberNumber') &&
                  userData.prescriberNumber != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="errors.first('prescriberNumber') ? true : false"
                v-validate="'required|digits:7'"
                name="prescriberNumber"
                :danger-text="errors.first('prescriberNumber')"
                data-vv-as="prescriber number"
                label="Prescriber Number"
                class="w-full"
                icon-no-border
                icon="icon icon-briefcase"
                icon-pack="feather"
                v-model="userData.prescriberNumber"
              />
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
            class="vs-con-loading__container"
            id="aphraUpload"
          >
            <div class="w-full m-5">
              <vs-col vs-align="left" vs-w="6">
                <label for="ahpraCertificate">AHPRA Certificate</label>
                <a
                  style="display: block"
                  id="ahpraCertificate"
                  v-if="userData.ahpraCertificate"
                  :href="userData.ahpraCertificate"
                  target="_blank"
                >
                  <div class="vx-col" id="avatar-col">
                    <div class="img-container mb-4">
                      <img
                        :src="userData.ahpraCertificate"
                        class="rounded w-full ml-2"
                      />
                    </div>
                  </div>
                </a>
                <p v-else id="ahpraCertificate">NA</p>
              </vs-col>
              <vs-col vs-align="left" vs-w="4">
                <div
                  class="mt-3"
                  style="text-align: center; position: relative"
                >
                  <label
                    class="image-upload"
                    style="
                      border: 1px solid #3dc9b3;
                      display: inline-block;
                      width: 100%;
                      border-radius: 25px;
                      padding: 5px;
                    "
                  >
                    Change Attachment
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .pdf"
                      @change="uploadImage($event, 'aphraUpload')"
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </vs-col>
            </div>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
            class="vs-con-loading__container"
            id="insuranceUpload"
          >
            <div class="w-full m-5">
              <vs-col vs-align="left" vs-w="6">
                <label for="insuranceCertificate">Insurance Certificate</label>
                <a
                  style="display: block"
                  id="insuranceCertificate"
                  v-if="userData.insuranceCertificate"
                  :href="userData.insuranceCertificate"
                  target="_blank"
                >
                  <div class="vx-col" id="avatar-col">
                    <div class="img-container mb-4">
                      <img
                        :src="userData.insuranceCertificate"
                        class="rounded w-full ml-2"
                      />
                    </div>
                  </div>
                </a>
                <p v-else id="insuranceCertificate">NA</p>
              </vs-col>

              <vs-col vs-align="left" vs-w="4">
                <div
                  class="mt-3"
                  style="text-align: center; position: relative"
                >
                  <label
                    class="image-upload"
                    style="
                      border: 1px solid #3dc9b3;
                      display: inline-block;
                      width: 100%;
                      border-radius: 25px;
                      padding: 5px;
                    "
                  >
                    Change Attachment
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .pdf"
                      @change="uploadImage($event, 'insuranceUpload')"
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </vs-col>
            </div>
          </vs-col>

          <vs-col
            vs-type="flex"
            vs-justify="left"
            vs-align="left"
            vs-w="6"
            class="vs-con-loading__container"
            id="signatureUpload"
          >
            <div class="w-full m-5">
              <vs-col vs-align="left" vs-w="6">
                <label for="signature">Signature</label>
                <a
                  style="display: block"
                  id="signature"
                  v-if="userData.signature"
                  :href="userData.signature"
                  target="_blank"
                >
                  <div class="vx-col" id="avatar-col">
                    <div class="img-container mb-4">
                      <img
                        :src="userData.signature"
                        class="rounded w-full ml-2"
                      />
                    </div>
                  </div>
                </a>
                <p v-else id="signature">NA</p>
              </vs-col>
              <vs-col vs-align="left" vs-w="4">
                <div
                  class="mt-3"
                  style="text-align: center; position: relative"
                >
                  <label
                    class="image-upload"
                    style="
                      border: 1px solid #3dc9b3;
                      display: inline-block;
                      width: 100%;
                      border-radius: 25px;
                      padding: 5px;
                    "
                  >
                    Change Attachment
                    <input
                      type="file"
                      accept=".png, .jpg, .jpeg, .pdf"
                      @change="uploadImage($event, 'signatureUpload')"
                      id="file-input"
                      hidden
                    />
                  </label>
                </div>
              </vs-col>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label>Account Status</label>
              <vs-switch
                v-model="statusSwitch"
                @click="editStatus()"
                class="ml-2"
              ></vs-switch>
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <label>Admin Approval</label>
              <vs-switch
                v-model="adminApprovalSwitch"
                @click="editApproval()"
                class="ml-2"
              ></vs-switch>
            </div>
          </vs-col>
        </vs-row>
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-button :disabled="!validateForm" @click="saveAppUserDetails"
                >Save Details</vs-button
              >
            </div>
          </vs-col>

          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-button color="danger" @click="popUpDelete = true"
                >Delete</vs-button
              >
              <vs-popup :active.sync="popUpDelete" title="Delete Confirmation">
                <p align="center">Are you sure you want to delete this user?</p>
                <br />
                <vs-row
                  vs-align="flex-start"
                  vs-type="flex"
                  vs-justify="left"
                  vs-w="12"
                  vs-mt="6"
                >
                  <vs-col vs-type="flex" vs-align="left" vs-w="6">
                    <div class="w-full m-5" align="right">
                      <vs-button
                        size="lg"
                        color="primary"
                        @click="deleteUserHandler"
                        >Yes, delete</vs-button
                      >
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-align="left" vs-w="6">
                    <div class="w-full m-5">
                      <vs-button
                        size="lg"
                        color="danger"
                        @click="popUpDelete = false"
                        >Cancel</vs-button
                      >
                    </div>
                  </vs-col>
                </vs-row>
              </vs-popup>
            </div>
          </vs-col>
        </vs-row>
      </vs-form>
    </vs-card>

    <vs-card>
      <h2 class="mb-2 ml-5 mt-2">Reset Password</h2>
      <form autocomplete="off">
        <vs-row
          vs-align="flex-start"
          vs-type="flex"
          vs-justify="left"
          vs-w="12"
        >
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                type="password"
                :success="
                  !errors.first('resetPassword.newPassword') && password != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('resetPassword.newPassword') ? true : false
                "
                name="newPassword"
                :danger-text="errors.first('resetPassword.newPassword')"
                label="New Password"
                class="w-full"
                v-validate="{
                  required: true,
                  min: 8,
                  regex:
                    /^.*(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(^[a-zA-Z0-9_.*\W]+$)/,
                }"
                data-vv-as="new password"
                data-vv-scope="resetPassword"
                ref="password"
                icon-no-border
                icon="icon icon-lock"
                icon-pack="feather"
                v-model="password"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-input
                type="password"
                label="Confirm Password"
                :success="
                  !errors.first('resetPassword.confirmPassword') &&
                  confirmPassword != ''
                "
                val-icon-success="done"
                val-icon-danger="error"
                :danger="
                  errors.first('resetPassword.confirmPassword') ? true : false
                "
                name="confirmPassword"
                :danger-text="errors.first('resetPassword.confirmPassword')"
                v-validate="'required|confirmed:password'"
                data-vv-as="confirm password"
                data-vv-scope="resetPassword"
                class="w-full"
                icon-no-border
                icon="icon icon-lock"
                icon-pack="feather"
                v-model="confirmPassword"
              />
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-align="left" vs-w="6">
            <div class="w-full m-5">
              <vs-button
                size="lg"
                @click="changePassword"
                icon-pack="feather"
                class="mr-2"
                >Reset Password</vs-button
              >
            </div>
          </vs-col>
        </vs-row>
      </form>
    </vs-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import NurseDetails from "@/views/components/nurses/nurseDetail.vue";
import Datepicker from "vuejs-datepicker";
import { Validator } from "vee-validate";
const dict = {
  custom: {
    firstName: {
      required: "Please enter your first name",
    },
    lastName: {
      required: "Please enter your last name",
    },
    email: {
      required: "Please enter valid email address",
    },
    contactNumber: {
      required: "Please enter your contact number",
    },
    prescriberNumber: {
      required: "Please enter your prescriber number with seven digits",
    },
    ahpraRegistrationNumber: {
      required: "Please enter valid AHPRA registration number",
    },
    ahpraExiryDate: {
      required: "Please enter valid AHPRA Expiry Date",
    },
    indemnityInsuranceProvider: {
      required: "Please enter valid indemnity insurance name",
    },
    indemnityInsuranceNumber: {
      required: "Please enter valid indemnity insurance number",
    },
    indemnityInsuranceExpiry: {
      required: "Please enter valid indemnity insurance expiry date",
    },
    newPassword: {
      required: "Please enter your password",
      min: "Password must be at least 6 characters",
      regex: "Must have at least one number and  one uppercase letter",
    },
    confirmPassword: {
      required: "Please enter your confirm password",
      confirmed: "The password confirmation does not match",
    },
  },
};
Validator.localize("en", dict);

export default {
  name: "adminEditDetail",
  components: {
    Datepicker,
    NurseDetails,
  },
  data: () => ({
    password: "",
    confirmPassword: "",
    userData: {
      userType: "",
      firstName: "",
      lastName: "",
      preferredName: "",
      contactNumber: "",
      email: "",
      prescriberNumber: "",
      ahpraRegistrationNumber: "",
      ahpraExiryDate: "",
      indemnityInsuranceProvider: "",
      indemnityInsuranceNumber: "",
      indemnityInsuranceExpiry: "",
      ahpraCertificate: "",
      insuranceCertificate: "",
      signature: "",
      status: "",
      hasApprovedByAdmin: "",
      profileImage: "",
    },
    statusSwitch: "",
    adminApprovalSwitch: "",
    popUpDelete: false,
    disabledDates: { to: new Date() },
  }),
  computed: {
    validateForm() {
      return (
        !this.errors.any() &&
        this.userData.firstName != "" &&
        this.userData.lastName != "" &&
        this.userData.contactNumber != "" &&
        this.userData.ahpraRegistrationNumber != "" &&
        this.userData.ahpraExiryDate != "" &&
        this.userData.indemnityInsuranceProvider != "" &&
        this.userData.indemnityInsuranceNumber != "" &&
        this.userData.indemnityInsuranceExpiry != "" &&
        this.userData.prescriberNumber != "" &&
        this.userData.ahpraCertificate != "" &&
        this.userData.insuranceCertificate != "" &&
        this.userData.signature != ""
      );
    },
  },
  methods: {
    ...mapActions("appUser", [
      "fetchAppUserDetails",
      "updateAppUserDetails",
      "deleteAppUser",
      "resetUserPassword",
    ]),
    ...mapActions("admin", ["checkEditEmail"]),
    getAppUserDetail(id) {
      this.fetchAppUserDetails(id).then((res) => {
        this.userData.content = res.data.data.content;
        this.userData.subject = res.data.data.subject;
        this.userData = res.data.data;
        if (this.userData.status == "Active") {
          this.statusSwitch = true;
        }
        if (this.userData.status == "Disabled") {
          this.statusSwitch = false;
        }
        if (this.userData.status == "Pending_Approval") {
          this.statusSwitch = false;
        }
        if (this.userData.hasApprovedByAdmin == true) {
          this.adminApprovalSwitch = true;
        }
        if (this.userData.hasApprovedByAdmin == false) {
          this.adminApprovalSwitch = false;
        }
      });
    },
    async saveAppUserDetails() {
      let checkData = {
        email: this.userData.email,
        id: this.userData._id,
      };
      await this.checkEditEmail(checkData)
        .then((res) => {
          let id = this.$route.params.id;
          let info = this.userData;
          this.updateAppUserDetails({ id, info }).then((res) => {
            this.$vs.notify({
              subject: "Update Page Content",
              text: res.data.message,
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
            // this.$router.push({name: "app-user"});
          });
        })
        .catch((err) => {
          if (err.response.status == 422) {
            this.errors.add({
              field: "email",
              msg: err.response.data.message,
            });
          } else {
            this.$vs.notify({
              title: "Edit Attempt",
              text: "Server Error",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "warning",
            });
          }
        });
    },
    editStatus() {
      if (this.statusSwitch === true) {
        this.userData.status = "Disabled";
      }
      if (this.statusSwitch === false) {
        this.userData.status = "Active";
      }
    },
    editApproval() {
      if (this.adminApprovalSwitch === true) {
        this.userData.hasApprovedByAdmin = "false";
      }
      if (this.adminApprovalSwitch === false) {
        this.userData.hasApprovedByAdmin = "true";
      }
    },
    deleteUserHandler() {
      this.popUpDelete = false;
      let id = this.$route.params.id;
      this.deleteAppUser(id).then((res) => {
        this.$vs.notify({
          subject: "Delete App User",
          text: res.data.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.$router.push({ name: "app-user" });
      });
    },
    async changePassword() {
      let self = this;
      const isValid = await this.$validator.validateAll("resetPassword");
      if (isValid) {
        await this.resetUserPassword({
          id: this.userData._id,
          data: {
            password: this.password,
            confirmPassword: this.confirmPassword,
          },
        }).then((res) => {
          self.password = "";
          self.confirmPassword = "";
          self.$validator.reset();
          self.$vs.notify({
            title: "User password reset",
            text: "User's password reset Successfully.",
            color: "success",
          });
          // this.$router.push({name:'app-user'});
        });
      }
    },
    uploadImage(event, key = "profileImage") {
      this.$vs.loading({
        text: "Uploading ...",
        color: "#3dc9d3",
        container: "#" + key,
        scale: 0.6,
      });
      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png",
        },
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then((res) => {
          if (key === "profileImageUpload") {
            self.userData.profileImage = res.data.Location;
          }
          if (key === "aphraUpload") {
            this.errors.remove("aphraCertificate");
            self.userData.ahpraCertificate = res.data.Location;
          }
          if (key === "insuranceUpload") {
            this.errors.remove("insuranceCertificate");
            self.userData.insuranceCertificate = res.data.Location;
          }
          if (key === "signatureUpload") {
            this.errors.remove("electronicSignature");
            self.userData.signature = res.data.Location;
          }
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
        })
        .catch((err) => {
          this.$vs.loading.close("#" + key + " > .con-vs-loading");
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          }
        });
    },
  },
  created() {
    let pageId = this.$route.params.id;
    this.getAppUserDetail(pageId);
  },
  mounted() {
    var d = new Date();
    d.setDate(d.getDate() - 1);
    this.disabledDates.to = d;
  },
};
</script>
<style lang="scss">
#avatar-col {
  width: 10rem;
}
ul {
  list-style: none;
  margin-left: 0px;
}
.bg-color-done {
  background: #d4f4e2;
  color: #28c76f !important;
  border: 1px solid #28c76f !important;
  border-left: hidden !important;
}
.bg-color-error {
  background: #fbdddd;
  color: #ea5455 !important;
  border: 1px solid #ea5455 !important;
  border-left: hidden !important;
}
.image-upload {
  cursor: pointer;
}
.register-tabs-container {
  min-height: 517px;

  .con-tab {
    padding-bottom: 23px;
  }
}
.disabled-upload {
  display: none;
}
.con-img-upload {
  height: 50%;
}
</style>
